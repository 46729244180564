h1, p {
    font-family: Lato;
  }
  
  .about,
  .projects,
  .contact,
  .rest {
    min-height: 60vh;
  }
  
  
  .home {
    text-align: center;
    background: #ffffff;
  }
  .about {
    background: #ffffff;
    min-height: 100vh;
    padding-top: 2rem;
  }
  .projects {
    background: #9db8e0;
    min-height: 100vh;
    padding-top: 5rem;
  }
  .contact {
    background: #8C9DA7;
    min-height: 100vh;
    padding-top: 5rem;
  }
  .rest {
    min-height: 4vh;
    background: rgb(51, 51, 51);
  }
  
  .customNav {
    background-color: rgb(44, 44, 44);
    height: 4rem;
    border-width:.15rem !important;
  }
  
  html {
    scroll-behavior: smooth;
  }
  
  .mainBackdrop{
    background-image: url('./images/bg3.jpg');
    height: 100vh;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
     background-size: cover;
    
  }

  
  .titleColor{
   color: rgb(255, 255, 255);
   font-weight: bold;
  
   -webkit-animation: tracking-in-expand 5s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
    animation: tracking-in-expand 5s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
  }
  /* ----------------------------------------------
   * Generated by Animista on 2022-2-3 7:39:12
   * Licensed under FreeBSD License.
   * See http://animista.net/license for more info. 
   * w: http://animista.net, t: @cssanimista
   * ---------------------------------------------- */
  
  /**
   * ----------------------------------------
   * animation tracking-in-expand
   * ----------------------------------------
   */
   @-webkit-keyframes tracking-in-expand {
    0% {
      letter-spacing: -0.5em;
      opacity: 0;
    }
    40% {
      opacity: 0.6;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes tracking-in-expand {
    0% {
      letter-spacing: -0.5em;
      opacity: 0;
    }
    40% {
      opacity: 0.6;
    }
    100% {
      opacity: 1;
    }
  }
  
  .card {
    margin-top: 1rem;
  }
  
  
  
  
  
  .nameColor{
    color: #3b6a92;

    font-weight: bold;
  }
  .homeIcon{
    color: #3b6a92;
  }
  .snowAnim{
    color: #3b6a92;
  }  
  
  .homeIcon:hover{
    -webkit-animation: jello-horizontal 0.9s both;
              animation: jello-horizontal 0.9s both;         
  }
  /* ----------------------------------------------
   * Generated by Animista on 2022-2-3 10:17:51
   * Licensed under FreeBSD License.
   * See http://animista.net/license for more info. 
   * w: http://animista.net, t: @cssanimista
   * ---------------------------------------------- */
  
  /**
   * ----------------------------------------
   * animation jello-horizontal
   * ----------------------------------------
   */
   @-webkit-keyframes jello-horizontal {
    0% {
      -webkit-transform: scale3d(1, 1, 1);
              transform: scale3d(1, 1, 1);
    }
    30% {
      -webkit-transform: scale3d(1.25, 0.75, 1);
              transform: scale3d(1.25, 0.75, 1);
    }
    40% {
      -webkit-transform: scale3d(0.75, 1.25, 1);
              transform: scale3d(0.75, 1.25, 1);
    }
    50% {
      -webkit-transform: scale3d(1.15, 0.85, 1);
              transform: scale3d(1.15, 0.85, 1);
    }
    65% {
      -webkit-transform: scale3d(0.95, 1.05, 1);
              transform: scale3d(0.95, 1.05, 1);
    }
    75% {
      -webkit-transform: scale3d(1.05, 0.95, 1);
              transform: scale3d(1.05, 0.95, 1);
    }
    100% {
      -webkit-transform: scale3d(1, 1, 1);
              transform: scale3d(1, 1, 1);
    }
  }
  @keyframes jello-horizontal {
    0% {
      -webkit-transform: scale3d(1, 1, 1);
              transform: scale3d(1, 1, 1);
    }
    30% {
      -webkit-transform: scale3d(1.25, 0.75, 1);
              transform: scale3d(1.25, 0.75, 1);
    }
    40% {
      -webkit-transform: scale3d(0.75, 1.25, 1);
              transform: scale3d(0.75, 1.25, 1);
    }
    50% {
      -webkit-transform: scale3d(1.15, 0.85, 1);
              transform: scale3d(1.15, 0.85, 1);
    }
    65% {
      -webkit-transform: scale3d(0.95, 1.05, 1);
              transform: scale3d(0.95, 1.05, 1);
    }
    75% {
      -webkit-transform: scale3d(1.05, 0.95, 1);
              transform: scale3d(1.05, 0.95, 1);
    }
    100% {
      -webkit-transform: scale3d(1, 1, 1);
              transform: scale3d(1, 1, 1);
    }
  }
  
  
  
  
  
  
  .croppedImage {
    width: 15rem;
    display: block;
    margin-left: auto;
    margin-right: auto;
  
    
  
  }
  
  
